import React from "react"

const SvgTrello = props => (
  <svg height="368pt" viewBox="0 0 368 368" width="368pt" {...props}>
    <path d="M328 0H40C17.945 0 0 17.945 0 40v288c0 22.055 17.945 40 40 40h288c22.055 0 40-17.945 40-40V40c0-22.055-17.945-40-40-40zm24 328c0 13.23-10.77 24-24 24H40c-13.23 0-24-10.77-24-24V40c0-13.23 10.77-24 24-24h288c13.23 0 24 10.77 24 24zm0 0" />
    <path d="M152 64H72c-4.426 0-8 3.586-8 8v224c0 4.414 3.574 8 8 8h80c4.426 0 8-3.586 8-8V72c0-4.414-3.574-8-8-8zm-8 224H80V80h64zm0 0M296 64h-80c-4.426 0-8 3.586-8 8v160c0 4.414 3.574 8 8 8h80c4.426 0 8-3.586 8-8V72c0-4.414-3.574-8-8-8zm-8 160h-64V80h64zm0 0" />
  </svg>
)

export default SvgTrello
