import React from "react"

function SvgClock(props) {
  return (
    <svg height={512} viewBox="0 0 64 64" width={512} {...props}>
      <linearGradient
        id="clock_svg__a"
        gradientUnits="userSpaceOnUse"
        x1={32}
        x2={32}
        y1={-0.612}
        y2={65.708}
      >
        <stop offset={0} stopColor="#ea3fdb" />
        <stop offset={1} stopColor="#e93e3a" />
      </linearGradient>
      <linearGradient
        id="clock_svg__b"
        x1={41}
        x2={41}
        xlinkHref="#clock_svg__a"
        y1={-0.612}
        y2={65.708}
      />
      <linearGradient
        id="clock_svg__c"
        gradientTransform="rotate(30 34.995 12.601)"
        x1={35}
        x2={35}
        xlinkHref="#clock_svg__a"
        y1={-0.612}
        y2={65.708}
      />
      <linearGradient
        id="clock_svg__d"
        gradientTransform="rotate(60 30.61 17.002)"
        x1={30.607}
        x2={30.607}
        xlinkHref="#clock_svg__a"
        y1={-0.612}
        y2={65.708}
      />
      <linearGradient
        id="clock_svg__e"
        x1={29}
        x2={29}
        xlinkHref="#clock_svg__a"
        y1={-0.612}
        y2={65.708}
      />
      <linearGradient
        id="clock_svg__f"
        gradientTransform="rotate(30 30.604 28.992)"
        x1={30.607}
        x2={30.607}
        xlinkHref="#clock_svg__a"
        y1={-0.612}
        y2={65.708}
      />
      <linearGradient
        id="clock_svg__g"
        gradientTransform="rotate(60 35.003 33.397)"
        x1={35}
        x2={35}
        xlinkHref="#clock_svg__a"
        y1={-0.612}
        y2={65.708}
      />
      <linearGradient
        id="clock_svg__h"
        gradientTransform="rotate(30 46.994 33.382)"
        x1={47}
        x2={47}
        xlinkHref="#clock_svg__a"
        y1={-0.612}
        y2={65.708}
      />
      <linearGradient
        id="clock_svg__i"
        gradientTransform="rotate(60 51.396 29.004)"
        x1={51.393}
        x2={51.393}
        xlinkHref="#clock_svg__a"
        y1={-0.612}
        y2={65.708}
      />
      <linearGradient
        id="clock_svg__j"
        x1={53}
        x2={53}
        xlinkHref="#clock_svg__a"
        y1={-0.612}
        y2={65.708}
      />
      <linearGradient
        id="clock_svg__k"
        gradientTransform="rotate(30 51.384 16.993)"
        x1={51.393}
        x2={51.393}
        xlinkHref="#clock_svg__a"
        y1={-0.612}
        y2={65.708}
      />
      <linearGradient
        id="clock_svg__l"
        gradientTransform="rotate(60 47.005 12.61)"
        x1={47}
        x2={47}
        xlinkHref="#clock_svg__a"
        y1={-0.612}
        y2={65.708}
      />
      <linearGradient
        id="clock_svg__m"
        x1={42.5}
        x2={42.5}
        xlinkHref="#clock_svg__a"
        y1={-0.612}
        y2={65.708}
      />
      <linearGradient
        id="clock_svg__n"
        x1={11}
        x2={11}
        xlinkHref="#clock_svg__a"
        y1={-0.612}
        y2={65.708}
      />
      <linearGradient
        id="clock_svg__o"
        x1={21}
        x2={21}
        xlinkHref="#clock_svg__a"
        y1={-0.612}
        y2={65.708}
      />
      <path
        d="M62 23a20.978 20.978 0 00-38.854-11H5a3 3 0 00-3 3v32a3 3 0 003 3h17.72l-1.5 6H20a3 3 0 00-3 3v2a1 1 0 001 1h20a1 1 0 001-1v-2a3 3 0 00-3-3h-1.22l-1.5-6H51a3 3 0 003-3v-7.539A20.958 20.958 0 0062 23zM5 14h17.055a20.812 20.812 0 00-1.815 6H4v-5a1 1 0 011-1zm32 45v1H19v-1a1 1 0 011-1h16a1 1 0 011 1zm-4.28-3h-9.44l1.5-6h6.44zM52 47a1 1 0 01-1 1H5a1 1 0 01-1-1V22h16.051c-.016.334-.051.662-.051 1a20.956 20.956 0 0032 17.854zm-11-5a19 19 0 1119-19 19.021 19.021 0 01-19 19z"
        fill="url(#clock_svg__a)"
      />
      <path
        d="M41 6a17 17 0 1017 17A17.019 17.019 0 0041 6zm0 32a15 15 0 1115-15 15.017 15.017 0 01-15 15z"
        fill="url(#clock_svg__b)"
      />
      <path d="M40 10h2v2h-2z" fill="url(#clock_svg__b)" />
      <path
        d="M34 11.607h2v2h-2z"
        fill="url(#clock_svg__c)"
        transform="rotate(-30 34.993 12.608)"
      />
      <path
        d="M29.607 16h2v2h-2z"
        fill="url(#clock_svg__d)"
        transform="rotate(-60 30.612 17)"
      />
      <path d="M28 22h2v2h-2z" fill="url(#clock_svg__e)" />
      <path
        d="M29.607 28h2v2h-2z"
        fill="url(#clock_svg__f)"
        transform="rotate(-30 30.599 28.997)"
      />
      <path
        d="M34 32.392h2v2h-2z"
        fill="url(#clock_svg__g)"
        transform="rotate(-60 35.004 33.395)"
      />
      <path d="M40 34h2v2h-2z" fill="url(#clock_svg__b)" />
      <path
        d="M46 32.392h2v2h-2z"
        fill="url(#clock_svg__h)"
        transform="rotate(-30 46.987 33.39)"
      />
      <path
        d="M50.392 28h2v2h-2z"
        fill="url(#clock_svg__i)"
        transform="rotate(-60 51.399 29.002)"
      />
      <path d="M52 22h2v2h-2z" fill="url(#clock_svg__j)" />
      <path
        d="M50.392 16h2v2h-2z"
        fill="url(#clock_svg__k)"
        transform="rotate(-30 51.381 17.002)"
      />
      <path
        d="M46 11.607h2v2h-2z"
        fill="url(#clock_svg__l)"
        transform="rotate(-60 47.005 12.607)"
      />
      <path
        d="M47 24v-2h-3.184A3 3 0 0042 20.184V15h-2v5.184A2.993 2.993 0 1043.816 24zm-6 0a1 1 0 111-1 1 1 0 01-1 1z"
        fill="url(#clock_svg__m)"
      />
      <path
        d="M11 24a5 5 0 105 5 5.006 5.006 0 00-5-5zm0 8a3 3 0 113-3 3 3 0 01-3 3zM15 36H7a1 1 0 00-1 1v8a1 1 0 001 1h8a1 1 0 001-1v-8a1 1 0 00-1-1zm-1 8H8v-6h6z"
        fill="url(#clock_svg__n)"
      />
      <g fill="url(#clock_svg__o)">
        <path d="M18 38h6v2h-6zM18 42h6v2h-6z" />
      </g>
    </svg>
  )
}

export default SvgClock
