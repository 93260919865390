import React from "react"

const SvgSmartphone = props => (
  <svg viewBox="0 0 472.811 472.811" {...props}>
    <path d="M358.75 0H114.061C97.555 0 84.128 13.428 84.128 29.934v412.944c0 16.505 13.428 29.934 29.934 29.934H358.75c16.506 0 29.934-13.428 29.934-29.934V29.934C388.683 13.428 375.256 0 358.75 0zM99.128 75.236h274.556v312.687H99.128V75.236zM114.061 15H358.75c8.234 0 14.934 6.699 14.934 14.934v35.302H99.128V29.934c0-8.235 6.699-14.934 14.933-14.934zM358.75 457.811H114.061c-8.234 0-14.934-6.699-14.934-14.934v-44.955h274.556v44.955c0 8.235-6.699 14.934-14.933 14.934z" />
    <path d="M236.406 404.552c-13.545 0-24.564 11.02-24.564 24.565s11.02 24.564 24.564 24.564 24.564-11.02 24.564-24.564-11.019-24.565-24.564-24.565zm0 39.129c-8.031 0-14.564-6.534-14.564-14.564 0-8.031 6.533-14.565 14.564-14.565s14.564 6.534 14.564 14.565c0 8.03-6.533 14.564-14.564 14.564zM202.406 47.645h68a5 5 0 100-10h-68a5 5 0 100 10zM184.409 47.645c1.31 0 2.6-.53 3.53-1.46.93-.94 1.47-2.22 1.47-3.54s-.54-2.6-1.47-3.54a5.034 5.034 0 00-3.53-1.46c-1.32 0-2.601.53-3.54 1.46-.93.93-1.46 2.22-1.46 3.54s.53 2.6 1.46 3.54c.93.93 2.22 1.46 3.54 1.46z" />
  </svg>
)

export default SvgSmartphone
