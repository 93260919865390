import React from "react"

const SvgShoppingList = props => (
  <svg viewBox="0 0 512 512" {...props}>
    <path d="M251.328 196.704c-6.24-6.24-16.384-6.272-22.656-.032L176 249.376l-20.672-20.704c-6.24-6.24-16.384-6.24-22.624 0s-6.24 16.384 0 22.624l32 32c3.104 3.136 7.2 4.704 11.296 4.704s8.192-1.568 11.328-4.672l64-64c6.24-6.24 6.24-16.384 0-22.624zM251.328 324.704c-6.24-6.24-16.384-6.272-22.656-.032L176 377.376l-20.672-20.672c-6.24-6.24-16.384-6.24-22.624 0s-6.24 16.384 0 22.624l32 32c3.104 3.104 7.2 4.672 11.296 4.672s8.192-1.568 11.328-4.672l64-64c6.24-6.24 6.24-16.384 0-22.624zM368 224h-64c-8.832 0-16 7.168-16 16s7.168 16 16 16h64c8.832 0 16-7.168 16-16s-7.168-16-16-16zM368 352h-64c-8.832 0-16 7.168-16 16s7.168 16 16 16h64c8.832 0 16-7.168 16-16s-7.168-16-16-16z" />
    <path d="M416 64h-64V48c0-8.832-7.168-16-16-16h-34.72C294.656 13.376 276.864 0 256 0s-38.656 13.376-45.28 32H176c-8.832 0-16 7.168-16 16v16H96c-17.632 0-32 14.368-32 32v384c0 17.632 14.368 32 32 32h320c17.632 0 32-14.368 32-32V96c0-17.632-14.368-32-32-32zm-224 0h32c8.832 0 16-7.168 16-16s7.168-16 16-16 16 7.168 16 16 7.168 16 16 16h32v32H192V64zm224 416H96V96h64v16c0 8.832 7.168 16 16 16h160c8.832 0 16-7.168 16-16V96h64v384z" />
  </svg>
)

export default SvgShoppingList
