import React from "react"

function SvgMoney(props) {
  return (
    <svg height="487pt" viewBox="-29 0 487 487.719" width="487pt" {...props}>
      <path d="M220.867 266.176a7.616 7.616 0 00-2.742-.094c-9.16-1.066-16.07-8.816-16.086-18.035a8 8 0 00-16 0c.024 15.394 10.32 28.879 25.164 32.953v8a8 8 0 0016 0v-7.516c17.133-3.586 28.777-19.543 26.977-36.953-1.805-17.41-16.473-30.64-33.977-30.644-10.031 0-18.164-8.133-18.164-18.164s8.133-18.164 18.164-18.164 18.164 8.132 18.164 18.164a8 8 0 0016 0c-.023-16.164-11.347-30.106-27.164-33.442V155a8 8 0 00-16 0v7.77c-16.508 4.507-27.133 20.535-24.86 37.496s16.747 29.62 33.86 29.617c9.899 0 17.973 7.926 18.152 17.82.184 9.895-7.597 18.113-17.488 18.473zm0 0" />
      <path d="M104.195 222.5c0 64.07 51.938 116.008 116.008 116.008S336.211 286.57 336.211 222.5s-51.938-116.008-116.008-116.008c-64.039.07-115.933 51.969-116.008 116.008zm116.008-100.008c55.234 0 100.008 44.774 100.008 100.008s-44.774 100.008-100.008 100.008S120.195 277.734 120.195 222.5c.063-55.207 44.801-99.945 100.008-100.008zm0 0" />
      <path d="M375.648 358.23l-62.668 29.61a51.043 51.043 0 00-43.515-26.852l-57.852-1.59a61.1 61.1 0 01-26.293-6.789l-5.886-3.05a103.833 103.833 0 00-96.176.101l.367-13.336a8 8 0 00-7.777-8.219L12.41 326.36a7.997 7.997 0 00-8.215 7.778L.363 473.347a8 8 0 007.778 8.22l63.437 1.746h.219a8 8 0 008-7.782l.183-6.66 16.48-8.824a28.269 28.269 0 0121.099-2.309l98.414 27.621c.172.051.343.09.52.13a105.348 105.348 0 0021.628 2.23 106.739 106.739 0 0044.59-9.73 7.43 7.43 0 00.992-.548l142.692-92.296a8.004 8.004 0 002.62-10.657c-10.593-18.797-34.09-25.957-53.367-16.258zM16.578 465.793l3.39-123.219 47.442 1.305-3.39 123.223zm258.926-2.094a90.704 90.704 0 01-55.469 6.192l-98.148-27.551a44.236 44.236 0 00-32.977 3.605l-8.422 4.512 2.254-81.926a87.938 87.938 0 0189.336-4.765l5.887 3.05a77.267 77.267 0 0033.21 8.579l57.856 1.59c16.25.468 30.051 12.038 33.348 27.96l-86.176-2.379c-4.418-.12-8.094 3.364-8.219 7.778a8.003 8.003 0 007.778 8.219l95.101 2.617h.223a8 8 0 007.992-7.782 50.772 50.772 0 00-.773-10.378l64.277-30.372c.063-.027.125-.058.188-.09a24.443 24.443 0 0127.64 3.872zm0 0M228.203 84V8a8 8 0 00-16 0v76a8 8 0 0016 0zm0 0M288.203 84V48a8 8 0 00-16 0v36a8 8 0 0016 0zm0 0M168.203 84V48a8 8 0 00-16 0v36a8 8 0 0016 0zm0 0" />
    </svg>
  )
}

export default SvgMoney
