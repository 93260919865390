import React from "react"

const SvgCrossPlatform = props => (
  <svg viewBox="0 0 64 64" {...props}>
    <path d="M59 2H5a3 3 0 00-3 3v54a3 3 0 003 3h54a3 3 0 003-3V5a3 3 0 00-3-3zm1 57a1 1 0 01-1 1H5a1 1 0 01-1-1V12h56zM4 10V5a1 1 0 011-1h54a1 1 0 011 1v5z" />
    <path d="M6.62 6.08a.9.9 0 00-.54.54.942.942 0 000 .76 1.032 1.032 0 00.21.33 1.155 1.155 0 00.33.21A1 1 0 007 8a1.225 1.225 0 00.19-.02.6.6 0 00.19-.06.757.757 0 00.18-.09 1.576 1.576 0 00.15-.12 1.014 1.014 0 000-1.42 1.017 1.017 0 00-1.09-.21zM11.38 6.08a.916.916 0 00-.76 0 .9.9 0 00-.54.54.942.942 0 000 .76 1.032 1.032 0 00.21.33 1.155 1.155 0 00.33.21.943.943 0 00.76 0 1.032 1.032 0 00.33-.21 1.014 1.014 0 000-1.42 1.032 1.032 0 00-.33-.21zM15.71 6.29a1 1 0 00-1.09-.21 1.155 1.155 0 00-.33.21 1.032 1.032 0 00-.21.33.942.942 0 000 .76 1.032 1.032 0 00.21.33 1.155 1.155 0 00.33.21A1 1 0 0015 8a1.052 1.052 0 00.71-.29 1.032 1.032 0 00.21-.33.943.943 0 000-.76 1.032 1.032 0 00-.21-.33zM9 58h46a3 3 0 003-3V17a3 3 0 00-3-3H25a3 3 0 00-3 3v11H9a3 3 0 00-3 3v24a3 3 0 003 3zm-1-3v-1h16v1a1 1 0 01-1 1H9a1 1 0 01-1-1zm0-21h16v18H8zm48 16H26V31a3 3 0 00-2-2.816V22h32zm-16 5a1 1 0 111-1 1 1 0 01-1 1zm-2.22 1H25.816A2.966 2.966 0 0026 55v-3h11.78a2.954 2.954 0 000 4zM55 56H42.22a2.954 2.954 0 000-4H56v3a1 1 0 01-1 1zM25 16h30a1 1 0 011 1v3H24v-3a1 1 0 011-1zM9 30h14a1 1 0 011 1v1H8v-1a1 1 0 011-1z" />
    <path d="M40 19a1.052 1.052 0 00.71-.29A1.033 1.033 0 0041 18a1 1 0 00-.08-.38.933.933 0 00-.21-.33 1.047 1.047 0 00-1.42 0 .933.933 0 00-.21.33A1 1 0 0039 18a1.033 1.033 0 00.29.71A1.052 1.052 0 0040 19z" />
  </svg>
)

export default SvgCrossPlatform
