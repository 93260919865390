import React from "react"
import IndexDesktop from "../components/index/indexDesktop"
import { Helmet } from "react-helmet"

const IndexPage = () => (
  <div>
    <Helmet htmlAttributes={{ lang: "pt-br" }}>
      <title>deliPOS - Sistema Para Restaurantes</title>
      <meta charSet="utf-8" />
      <meta
        name="description"
        content="O sistema definitivo para acompanhar a operação de restaurantes, bares e cafés. Compatível com web, android e iOS."
      />
    </Helmet>

    <IndexDesktop />
  </div>
)

export default IndexPage
