import React from "react"

function SvgCounter(props) {
  return (
    <svg height={640} viewBox="0 -16 480 480" width={640} {...props}>
      <path d="M456 191.977H24c-13.25.015-23.984 10.75-24 24V424c.016 13.25 10.75 23.984 24 24h432c13.25-.016 23.984-10.75 24-24V215.977c-.016-13.25-10.75-23.985-24-24zM456 432H24a8.005 8.005 0 01-8-8v-96.023l111.96.023v-16L16 311.977v-96a8.005 8.005 0 018-8h432a8.005 8.005 0 018 8v96L351.96 312v16l112.04-.023V424a8.005 8.005 0 01-8 8zm0 0M192 151.977c0-1.63.563-39.844 47.75-39.989l.21.012.255-.012c46.035.117 47.746 35.938 47.785 39.989V176h16v-24.023a57.76 57.76 0 00-34.004-50.59c15.895-12.75 22.008-34.149 15.25-53.371-6.762-19.223-24.926-32.082-45.3-32.075-20.38.008-38.532 12.88-45.278 32.11-6.746 19.23-.617 40.62 15.289 53.36A57.772 57.772 0 00176 151.976V176h16zM207.96 64c-.007-17.652 14.282-31.98 31.935-32.016 17.656-.035 32.003 14.23 32.066 31.887.062 17.652-14.184 32.024-31.836 32.113l-.125-.007-.176.007c-17.61-.086-31.84-14.375-31.863-31.984zm0 0" />
      <path d="M239.96 224c-53.019 0-96 42.98-96 96s42.981 96 96 96c53.02 0 96-42.98 96-96-.058-52.996-43.003-95.941-96-96zm0 176c-44.183 0-80-35.816-80-80s35.817-80 80-80c44.185 0 80 35.816 80 80-.05 44.16-35.839 79.95-80 80zm0 0M47.96 176h104V96h-104a23.895 23.895 0 00-17.757 40 23.897 23.897 0 0017.758 40zm88-16h-88a8 8 0 010-16h88zm-88-48h88v16h-88a8 8 0 010-16zm0 0M335.96 168a8.005 8.005 0 018-8h112a8.005 8.005 0 018 8v8h16v-8c-.015-13.25-10.75-23.984-24-24h-48v-16h48c13.25-.016 23.985-10.75 24-24V24c-.015-13.25-10.75-23.984-24-24h-112c-13.25.016-23.983 10.75-24 24v80c.017 13.25 10.75 23.984 24 24h48v16h-48c-13.25.016-23.983 10.75-24 24v8h16zm0-64V24a8.005 8.005 0 018-8h112a8.005 8.005 0 018 8v80a8.005 8.005 0 01-8 8h-17.472a23.81 23.81 0 001.473-8V72c-.016-13.25-10.75-23.984-24-24h-32c-13.25.016-23.984 10.75-24 24v32c.027 2.73.527 5.438 1.476 8h-17.476a8.005 8.005 0 01-8-8zm48 8a8.005 8.005 0 01-8-8V72a8.005 8.005 0 018-8h32a8.005 8.005 0 018 8v32a8.005 8.005 0 01-8 8h-8V88h-16v24zm0 0" />
      <path d="M279.96 296v-8c-.01-8.832-7.167-15.988-16-16h-16v-16h-16v16h-16c-8.831.012-15.987 7.168-16 16v24c.013 8.832 7.169 15.988 16 16h16v24h-16v-8h-16v8c.013 8.832 7.169 15.988 16 16h16v16h16v-16h16c8.833-.012 15.99-7.168 16-16v-24c-.01-8.832-7.167-15.988-16-16h-16v-24h16v8zm-16 32v24h-16v-24zm-32-16h-16v-24h16zm0 0" />
    </svg>
  )
}

export default SvgCounter
