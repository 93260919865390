import React from "react"

function SvgMeal(props) {
  return (
    <svg viewBox="0 0 64 64" width={512} height={512} {...props}>
      <defs>
        <linearGradient
          id="meal_svg__a"
          x1={32.027}
          y1={0.13}
          x2={32.027}
          y2={66.013}
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0} stopColor="#87a8c5" />
          <stop offset={1} stopColor="#723fea" />
        </linearGradient>
        <linearGradient
          id="meal_svg__b"
          x1={32}
          y1={0.13}
          x2={32}
          y2={66.013}
          xlinkHref="#meal_svg__a"
        />
        <linearGradient
          id="meal_svg__c"
          x1={42.231}
          y1={0.13}
          x2={42.231}
          y2={66.013}
          xlinkHref="#meal_svg__a"
        />
        <linearGradient
          id="meal_svg__d"
          x1={31}
          y1={0.13}
          x2={31}
          y2={66.013}
          xlinkHref="#meal_svg__a"
        />
        <linearGradient
          id="meal_svg__e"
          x1={4}
          y1={0.13}
          x2={4}
          y2={66.013}
          xlinkHref="#meal_svg__a"
        />
        <linearGradient
          id="meal_svg__f"
          x1={10}
          y1={0.13}
          x2={10}
          y2={66.013}
          xlinkHref="#meal_svg__a"
        />
        <linearGradient
          id="meal_svg__g"
          x1={54}
          y1={0.13}
          x2={54}
          y2={66.013}
          xlinkHref="#meal_svg__a"
        />
        <linearGradient
          id="meal_svg__h"
          x1={60}
          y1={0.13}
          x2={60}
          y2={66.013}
          xlinkHref="#meal_svg__a"
        />
      </defs>
      <path
        d="M61.525 35.9a4 4 0 00-5.439-1.462L38 44.978A5.005 5.005 0 0033 40H13.013a.99.99 0 00-.242.03l-2.8.7A2.993 2.993 0 007 38H5a3 3 0 00-3 3v18a3 3 0 003 3h2a2.994 2.994 0 002.972-2.72l2.788.691A1.017 1.017 0 0013 60h13.74a5.056 5.056 0 002.514-.676l30.821-17.961a4.032 4.032 0 001.45-5.463zM8 59a1 1 0 01-1 1H5a1 1 0 01-1-1V41a1 1 0 011-1h2a1 1 0 011 1zm51.066-19.364L28.253 57.593A3.054 3.054 0 0126.74 58H13.122L10 57.227V42.781L13.136 42H33a2.992 2.992 0 012.573 4.533 3.093 3.093 0 01-.445.585A3.018 3.018 0 0133 48H23v2h10a4.927 4.927 0 004.143-2.208l19.944-11.625a1.967 1.967 0 012.7.728 2.023 2.023 0 01-.721 2.741z"
        fill="url(#meal_svg__a)"
      />
      <path
        d="M8.105 31.447A1 1 0 009 32h46a1 1 0 00.9-.553l2-4A1 1 0 0057 26h-2.051A21.011 21.011 0 0036 6.1V6a4 4 0 00-8 0v.1A21.011 21.011 0 009.051 26H7a1 1 0 00-.895 1.447zM32 4a2 2 0 012 2h-4a2 2 0 012-2zm-2 4h4a19.007 19.007 0 0118.949 18h-41.9A19.007 19.007 0 0130 8zm25.382 20l-1 2H9.618l-1-2z"
        fill="url(#meal_svg__b)"
      />
      <path
        d="M34 10v2a15 15 0 0114.526 11.25l1.936-.5A17 17 0 0034 10z"
        fill="url(#meal_svg__c)"
      />
      <path fill="url(#meal_svg__d)" d="M30 10h2v2h-2z" />
      <path
        d="M2.4 12h2a1.552 1.552 0 01.412-1.083 3.611 3.611 0 000-4.5A1.558 1.558 0 014.4 5.33a1.542 1.542 0 01.412-1.08A3.5 3.5 0 005.6 2h-2a1.553 1.553 0 01-.412 1.081A3.487 3.487 0 002.4 5.33a3.5 3.5 0 00.788 2.252 1.631 1.631 0 010 2.167A3.493 3.493 0 002.4 12z"
        fill="url(#meal_svg__e)"
      />
      <path
        d="M8.4 12h2a1.552 1.552 0 01.412-1.083 3.611 3.611 0 000-4.5A1.558 1.558 0 0110.4 5.33a1.542 1.542 0 01.412-1.08A3.5 3.5 0 0011.6 2h-2a1.553 1.553 0 01-.412 1.081A3.487 3.487 0 008.4 5.33a3.5 3.5 0 00.788 2.252 1.631 1.631 0 010 2.167A3.493 3.493 0 008.4 12z"
        fill="url(#meal_svg__f)"
      />
      <path
        d="M52.4 12h2a1.552 1.552 0 01.412-1.083 3.611 3.611 0 000-4.5A1.558 1.558 0 0154.4 5.33a1.542 1.542 0 01.412-1.08A3.5 3.5 0 0055.6 2h-2a1.553 1.553 0 01-.412 1.081A3.487 3.487 0 0052.4 5.33a3.5 3.5 0 00.788 2.252 1.631 1.631 0 010 2.167A3.493 3.493 0 0052.4 12z"
        fill="url(#meal_svg__g)"
      />
      <path
        d="M58.4 12h2a1.552 1.552 0 01.412-1.083 3.611 3.611 0 000-4.5A1.558 1.558 0 0160.4 5.33a1.542 1.542 0 01.412-1.08A3.5 3.5 0 0061.6 2h-2a1.553 1.553 0 01-.412 1.081A3.487 3.487 0 0058.4 5.33a3.5 3.5 0 00.788 2.252 1.631 1.631 0 010 2.167A3.493 3.493 0 0058.4 12z"
        fill="url(#meal_svg__h)"
      />
    </svg>
  )
}

export default SvgMeal
