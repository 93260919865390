import React from "react"

const SvgBarChart = props => (
  <svg viewBox="0 0 512 512" {...props}>
    <path d="M488.399 492h-21.933V173.536c0-14.823-12.06-26.882-26.882-26.882H390.56c-14.823 0-26.882 12.06-26.882 26.882V492h-55.692V317.825c0-14.823-12.059-26.882-26.882-26.882H232.08c-14.823 0-26.882 12.06-26.882 26.882V492h-55.692v-90.204c0-14.823-12.06-26.882-26.882-26.882H73.599c-14.823 0-26.882 12.06-26.882 26.882V492H23.601c-5.523 0-10 4.477-10 10s4.477 10 10 10h464.798c5.523 0 10-4.477 10-10s-4.477-10-10-10zm-358.895 0H66.716v-90.204a6.89 6.89 0 016.882-6.882h49.024a6.89 6.89 0 016.882 6.882V492zm158.481 0h-62.788V317.825a6.89 6.89 0 016.882-6.882h49.024a6.89 6.89 0 016.882 6.882V492zm158.481 0h-62.788V173.536a6.89 6.89 0 016.882-6.882h49.024a6.89 6.89 0 016.882 6.882V492zM466.442 10.516A9.965 9.965 0 00455.95.024c-.161-.007-.32-.024-.484-.024h-60.5c-5.523 0-10 4.477-10 10s4.477 10 10 10h37.357l-98.857 98.858-37.28-37.28a10.001 10.001 0 00-14.142 0l-179.769 179.77c-3.905 3.905-3.905 10.237 0 14.143 1.953 1.951 4.512 2.927 7.071 2.927s5.119-.976 7.071-2.929L289.115 102.79l37.28 37.28c3.905 3.905 10.237 3.905 14.143 0L446.466 34.143v33.81c0 5.523 4.477 10 10 10s10-4.477 10-10V11c0-.163-.017-.322-.024-.484z" />
    <circle cx={75.64} cy={303.31} r={10} />
  </svg>
)

export default SvgBarChart
