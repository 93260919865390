import React from "react"

function SvgDashboard(props) {
  return (
    <svg height={512} viewBox="0 0 64 64" width={512} {...props}>
      <linearGradient
        id="dashboard_svg__a"
        gradientUnits="userSpaceOnUse"
        x1={32}
        x2={32}
        y1={63}
        y2={1}
      >
        <stop offset={0} stopColor="#723fea" />
        <stop offset={1} stopColor="#87a8c5" />
      </linearGradient>
      <path
        d="M47 41c-3.309 0-6 2.691-6 6s2.691 6 6 6 6-2.691 6-6-2.691-6-6-6zm0 10c-2.206 0-4-1.794-4-4s1.794-4 4-4 4 1.794 4 4-1.794 4-4 4zM63 8c0-3.859-3.14-7-7-7s-7 3.141-7 7H1v40h30.051c.52 8.356 7.465 15 15.949 15 8.822 0 16-7.178 16-16 0-5.039-2.347-9.535-6-12.47V14.92c3.387-.488 6-3.401 6-6.92zm-7-5c2.757 0 5 2.243 5 5s-2.243 5-5 5-5-2.243-5-5 2.243-5 5-5zM33 39.271A15.88 15.88 0 0031.811 42H31v-8h2zm13-6.22v4c-5.046.504-9 4.773-9 9.949 0 1.463.324 2.85.891 4.104l-3.469 2.003A13.881 13.881 0 0133 47c0-7.382 5.747-13.433 13-13.949zM47 55c-4.411 0-8-3.589-8-8s3.589-8 8-8 8 3.589 8 8-3.589 8-8 8zm0 6c-4.814 0-9.068-2.443-11.588-6.155l3.483-2.011C40.713 55.352 43.664 57 47 57s6.287-1.648 8.105-4.166l3.483 2.011C56.068 58.557 51.814 61 47 61zm14-14c0 2.191-.52 4.259-1.422 6.107l-3.469-2.003A9.934 9.934 0 0057 47c0-5.176-3.954-9.446-9-9.949v-4c7.253.516 13 6.567 13 13.949zM47 31c-4.78 0-9.066 2.118-12 5.453V32h-6v12h2.292a16.042 16.042 0 00-.241 2H3V10h46.295a6.995 6.995 0 001.107 2.185l-6.116 6.116C43.894 18.113 43.462 18 43 18s-.894.113-1.285.301L39.7 16.286c.187-.392.3-.824.3-1.286 0-1.654-1.346-3-3-3s-3 1.346-3 3c0 .462.113.894.301 1.285L29.286 21.3A2.972 2.972 0 0028 21c-1.654 0-3 1.346-3 3s1.346 3 3 3 3-1.346 3-3a2.95 2.95 0 00-.301-1.285l5.015-5.015c.392.187.824.3 1.286.3s.894-.113 1.285-.301l2.015 2.015c-.187.392-.3.824-.3 1.286 0 1.654 1.346 3 3 3s3-1.346 3-3a2.95 2.95 0 00-.301-1.285l6.117-6.116A6.957 6.957 0 0055 14.921v18.245A15.874 15.874 0 0047 31zm-4-11a1.001 1.001 0 11-1 1c0-.552.449-1 1-1zm-7-5a1.001 1.001 0 111 1c-.551 0-1-.448-1-1zm-7 9a1.001 1.001 0 01-2 0 1.001 1.001 0 012 0zM7 14H5v-2h2zm16 0H9v-2h14zM7 18H5v-2h2zm16 0H9v-2h14zM7 22H5v-2h2zm16 0H9v-2h14zM7 26H5v-2h2zm16 0H9v-2h14zM5 44h6V34H5zm2-8h2v6H7zm6 8h6v-8h-6zm2-6h2v4h-2zm6 6h6V28h-6zm2-14h2v12h-2z"
        fill="url(#dashboard_svg__a)"
      />
    </svg>
  )
}

export default SvgDashboard
