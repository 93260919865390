import React from "react"
import styled from "styled-components"
import mockup from "../../images/mockup.png"
import Footer from "../footer.js"
import { FaWhatsapp } from "react-icons/fa"
import Masonry from "react-masonry-component"
import { Row, Col } from "antd"
import BrowserApp from "../../images/desktop.png"

//DNA Cards Icons
import CrossPlatform from "../../images/DNACards/CrossPlatform.js"
import Group from "../../images/DNACards/Group.js"
import ShoppingList from "../../images/DNACards/ShoppingList.js"
import OpenedPackaged from "../../images/DNACards/OpenedPackaged.js"
import Apple from "../../images/DNACards/Apple.js"
import Checklist from "../../images/DNACards/Checklist.js"
import Trello from "../../images/DNACards/Trello.js"
import Network from "../../images/DNACards/Network.js"
import BarChart from "../../images/DNACards/BarChart.js"
import CoffeeCup from "../../images/DNACards/CoffeeCup.js"
import Timer from "../../images/DNACards/Timer.js"
import Smartphone from "../../images/DNACards/Smartphone.js"
import LightBolt from "../../images/DNACards/LightBolt.js"
import Tap from "../../images/DNACards/Tap.js"
import Chemistry from "../../images/DNACards/Chemistry.js"
import Lifesaver from "../../images/DNACards/Lifesaver.js"

//BrowserApp Icons
import Sync from "../../images/BrowserApp/Sync.js"
import Web from "../../images/BrowserApp/Web.js"
import Counter from "../../images/BrowserApp/Counter.js"
import Money from "../../images/BrowserApp/Money.js"

//Overview Icons
import Dashboard from "../../images/Overview/Dashboard.js"
import Meal from "../../images/Overview/Meal.js"
import Clock from "../../images/Overview/Clock.js"

const masonryOptions = {
  transitionDuration: 500,
  gutter: 20,
  fitWidth: true,
}

const DNACardColors = [
  "#f8b37b",
  "#f39b73",
  "#ed8771",
  "#e67874",
  "#de6e7d",
  "#E57297",
  "#E57BB7",
  "#CF83D3",
  "#a97ecb",
  "#a587cd",
  "#a090cd",
  "#9b97cd",
  "#969dcd",
  "#91a2cb",
  "#8ca5c8",
  "#87a8c5",
]

const DNACardIcons = {
  0: (
    <CrossPlatform
      fill={DNACardColors[0]}
      width={40}
      height={40}
      style={{ float: "right" }}
    />
  ),
  1: (
    <Group
      fill={DNACardColors[1]}
      width={40}
      height={40}
      style={{ float: "right" }}
    />
  ),
  2: (
    <ShoppingList
      fill={DNACardColors[2]}
      width={40}
      height={40}
      style={{ float: "right" }}
    />
  ),
  3: (
    <OpenedPackaged
      fill={DNACardColors[3]}
      width={40}
      height={40}
      style={{ float: "right" }}
    />
  ),
  4: (
    <Apple
      fill={DNACardColors[4]}
      width={40}
      height={40}
      style={{ float: "right" }}
    />
  ),
  5: (
    <Checklist
      fill={DNACardColors[5]}
      width={40}
      height={40}
      style={{ float: "right" }}
    />
  ),
  6: (
    <Trello
      fill={DNACardColors[6]}
      width={40}
      height={40}
      style={{ float: "right" }}
    />
  ),
  7: (
    <Network
      fill={DNACardColors[7]}
      width={40}
      height={40}
      style={{ float: "right" }}
    />
  ),
  8: (
    <BarChart
      fill={DNACardColors[8]}
      width={40}
      height={40}
      style={{ float: "right" }}
    />
  ),
  9: (
    <CoffeeCup
      fill={DNACardColors[9]}
      width={40}
      height={40}
      style={{ float: "right" }}
    />
  ),
  10: (
    <Timer
      fill={DNACardColors[10]}
      width={40}
      height={40}
      style={{ float: "right" }}
    />
  ),
  11: (
    <Smartphone
      fill={DNACardColors[11]}
      width={40}
      height={40}
      style={{ float: "right" }}
    />
  ),
  12: (
    <LightBolt
      fill={DNACardColors[12]}
      width={40}
      height={40}
      style={{ float: "right" }}
    />
  ),
  13: (
    <Tap
      fill={DNACardColors[13]}
      width={40}
      height={40}
      style={{ float: "right" }}
    />
  ),
  14: (
    <Chemistry
      fill={DNACardColors[14]}
      width={40}
      height={40}
      style={{ float: "right" }}
    />
  ),
  15: (
    <Lifesaver
      fill={DNACardColors[15]}
      width={40}
      height={40}
      style={{ float: "right" }}
    />
  ),
}

const DNACardTitles = {
  0: "Multi-Plataforma",
  1: "Acesso de Colaboradores",
  2: "Controle de Comanda",
  3: "Estoque por Item",
  4: "Estoque por Ingredientes",
  5: "Cadastro de Opcionais",
  6: "Kanban para Pedidos",
  7: "Multi-KDS",
  8: "Métricas Detalhadas",
  9: "Diversos Modos",
  10: "Acompanhamento dos Pedidos",
  11: "Tablet, Celular ou Computador",
  12: "Alta Performance",
  13: "Interface Intuitiva",
  14: "Soluções Personalizadas",
  15: "Workshops, Treinamento e Suporte",
}

const DNACardTexts = {
  0: "Com o sistema para restaurantes deli, você pode utilizar smartphones, tablets ou até mesmo um navegador para gravar as comandas eletrônicas. Além disso, sua equipe pode conectar quantos dispositvos quiser à nossa plataforma.",
  1: "Cada colaborador tem o seu próprio acesso, facilitando a utilização de vários dispositivos simultaneamente. Contas novas podem ser criadas na página do gestor.",
  2: "Saber com segurança o consumo de cada mesa é fundamental para a satisfação dos seus clientes. Por isso, investimos num sistema para restaurantes robusto, que permitem fazer qualquer atualização em poucos cliques.",
  3: "Comida é um bem perecível e é uma pena desperdiçá-la. Mas e se o volume de vendas estiver crescendo? Na página do gestor, você controla o estoque e recebe métricas que demonstram como abastecer sua cozinha de uma maneira sistemática.",
  4: "Insira a receita dos seus produtos na página do gestor para verificar o consumo dos ingredientes. Cada venda realizada deduz do estoque as quantias informadas.",
  5: "Caso necessário, cadastre opcionais no sistema. Dependendo da configuração vigente, o mesmo produto pode ser marcado na comanda eletrônica com mais de um opcional. Além disso, os opcionais podem modificar o consumo de ingredientes.",
  6: "Use metodologias ágeis! Desenvolvemos uma página especialmente para os ambientes de preparação de alimentos. Com o Kitchen Display Screen (KDS), os pedidos aparecem em tempo real, conforme o seu estado de execução.",
  7: "A grande vantagens do KDS é a capacidade de ser distribuído em múltiplas estações. Portanto, pode haver uma tela onde chegam só os pedidos de bebidas e outra separada onde chegam só os pedidos das sobremesas, por exemplo.",
  8: "Temos um forte compromisso com a otimização das atividades de nossos clientes. Para tal, a página do gestor traz uma análise pormenorizada dos dados gerados nas vendas na forma de gráficos, e tabelas.",
  9: "A flexibilidade da plataforma deli sempre foi uma prioridade para nossa equipe. Nos orgulhamos de criar um sistema para restaurantes com diversos tipos de operação. Atendemos desde food-trucks até os restaurantes mais sofisticados.",
  10: "No Kitchen Display Screen, os pedidos têm 3 estados possíveis: em espera, em execução e entregue. Esse estado pode ser verificado a qualquer momento no aplicativo móvel, utilizado por quem está servindo à mesa.",
  11: "Além de sermos multi-plataforma, você pode escolher qualquer combinação de dispositivos para operar no seu estabelecimento. Tomamos essa abordagem para que nossos clientes não tenham necessidade de adquirir dispositivos específicos.",
  12: "Nosso sistema para restaurantes é leve e veloz. Estamos constantemente testando e estudando novas tecnlogias para garantir que entregamos aplicações rápidas e interfaces fluidas.",
  13: "Nossas interfaces são fruto do feedback transparente dado pelos nossos usuários. Nesse sentido, estamos sempre dispostos a ouvir sugestões e críticas, buscando a melhoria contínua dos nossos serviços e experiências.",
  14: "Sentiu falta de algo? Entre em contato com nossa equipe! O sistema deli é um organismo vivo feito para se adaptar a cenários diferentes. Estamos dispostos a trabalhar juntos e pensar em soluções conforme as suas necessidades ou restrições.",
  15: "Acreditamos que bom software dispensa manuais. Porém, estamos sempre dispostos a sanar qualquer dúvida. Quer saber mais sobre a plataforma? Fale com a gente e se inscreva nas nossas redes sociais!",
}

class IndexDesktop extends React.Component {
  state = {
    elements: [...Array(16).keys()],
  }

  toggleExpand(el) {
    if (this.state.expanded === el) {
      this.setState({ ...this.state, expanded: undefined })
      return
    }

    this.setState({ ...this.state, expanded: el })
  }

  render() {
    const childElements = this.state.elements.map(element => {
      if (true) {
        return (
          <li style={{ listStyleType: "none", marginBottom: "20px" }}>
            <DNACardExpanded onClick={e => this.toggleExpand(element)}>
              {DNACardIcons[element]}
              <DNATitleContainer color={DNACardColors[element]}>
                {DNACardTitles[element]}
              </DNATitleContainer>

              <p
                style={{
                  marginTop: "48px",
                  color: DNACardColors[element],
                  fontFamily: "IBM Plex Sans",
                }}
              >
                {DNACardTexts[element]}
              </p>
            </DNACardExpanded>
          </li>
        )
      }
      return (
        <li style={{ listStyleType: "none" }}>
          <DNACard onClick={e => this.toggleExpand(element)}>
            {DNACardIcons[element]}
            <DNATitleContainer color={DNACardColors[element]}>
              {DNACardTitles[element]}
            </DNATitleContainer>
          </DNACard>
        </li>
      )
    })

    return (
      <OuterContainer>
        <Header>
          <Row style={{ height: "100%" }}>
            <Col
              xs={6}
              sm={6}
              md={6}
              lg={4}
              xl={4}
              style={{
                display: "flex",
                flexDirection: "row",
                height: "100%",
                justifyContent: "flex-start",
                paddingLeft: "24px",
                alignItems: "center",
              }}
            >
              <p
                style={{
                  fontFamily: "Righteous",
                  fontSize: "1.8em",
                  width: "64px",
                  margin: "0px",
                }}
              >
                deli
              </p>
            </Col>

            <Col xs={0} sm={0} md={0} lg={4} xl={4}></Col>
            <Col xs={0} sm={0} md={0} lg={4} xl={4}></Col>

            <Col xs={0} sm={0} md={0} lg={4} xl={4}></Col>

            <Col xs={0} sm={0} md={0} lg={4} xl={4}></Col>
            <Col
              xs={18}
              sm={18}
              md={18}
              lg={4}
              xl={4}
              style={{
                display: "flex",
                flexDirection: "row",
                height: "100%",
                justifyContent: "flex-end",
                alignItems: "center",
                paddingRight: "24px",
              }}
            >
              <FaWhatsapp size="1.78em" />
              <span
                style={{
                  fontFamily: "IBM Plex Sans",
                  color: "#3f6aea",
                  fontSize: "1.2em",
                  marginLeft: "6px",
                }}
              >
                {"(41) 9 9757-4479"}
              </span>
            </Col>
          </Row>
        </Header>
        <BannerContainer>
          <Center>
            <Valign>
              <div style={{ maxWidth: "900px", display: "inline-block" }}>
                <Logo>deli</Logo>
                <Summary>
                  Sistema para restaurantes completo para acompanhar seu
                  negócio.
                </Summary>
                <Summary2>
                  Conheça nossos aplicativos para android, iOS e web.
                </Summary2>
              </div>

              <img
                alt="Aplicativo Métricas Restaurante"
                src={mockup}
                style={{
                  display: "inline-block",
                  minWidth: "320px",
                  width: "70%",
                }}
              />
            </Valign>
          </Center>
        </BannerContainer>

        <OverviewContainer>
          <Center>
            <Valign>
              <div
                style={{
                  marginTop: "26px",
                  color: "#222222",
                  fontFamily: "IBM Plex Sans",
                  fontWeight: "bold",
                  fontSize: "2rem",
                }}
              >
                AUTOMAÇÃO É FUNDAMENTAL
              </div>
              <div
                style={{
                  display: "inline-block",
                  width: "216px",
                  height: "8px",
                  backgroundColor: "#3f6aea",
                }}
              ></div>
              <h3
                style={{
                  color: "#222222",
                  fontWeight: "lighter",
                  marginTop: "26px",
                  display: "inline-block",
                  width: "90%",
                }}
              >
                Desenvolvemos tecnologias que organizam e destacam a sua
                atividade.
              </h3>

              <OverviewCardContainer>
                <Row gutter={[26, 26]}>
                  <Col xs={24} sm={24} md={24} lg={8} xl={8}>
                    <OverviewCard1>
                      <Dashboard
                        width={200}
                        height={200}
                        style={{ marginTop: "30px" }}
                      />
                      <OverviewTitle1>Análise do Histórico</OverviewTitle1>
                      <OverviewDivisor1></OverviewDivisor1>

                      <OverviewText1>
                        Sem desperdícios! Cada produto vendido fica registrado.
                        Nosso sistema para restaurantes disponibiliza gráficos e
                        relatórios completos para você entender melhor os altos
                        e baixos da sua atividade. Assim fica fácil saber quais
                        os itens do seu cardápio que deixam sua conta no
                        positivo.
                      </OverviewText1>
                    </OverviewCard1>
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={8} xl={8}>
                    <OverviewCard2>
                      <Meal
                        width={200}
                        height={200}
                        style={{ marginTop: "30px" }}
                      />
                      <OverviewTitle2>Excelência no Atendimento</OverviewTitle2>
                      <OverviewDivisor2 />
                      <OverviewText2>
                        Marcar pedidos e enviá-los para a cozinha nunca foi tão
                        fácil. Com a deli, você e seus colaboradores podem
                        indicar opcionais e até escrever comentários na comanda
                        eletrônica, priorizando a escolha do cliente.
                      </OverviewText2>
                    </OverviewCard2>
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={8} xl={8}>
                    <OverviewCard3>
                      <Clock
                        width={200}
                        height={200}
                        style={{ marginTop: "30px" }}
                      />
                      <OverviewTitle3>Agilidade e Consistência</OverviewTitle3>
                      <OverviewDivisor3 />
                      <OverviewText3>
                        Os pedidos são organizados com a nossa ferramenta de
                        Kanban - o "Kitchen Display Screen". Nesta tela, os
                        pedidos marcados na plataforma aparecem na cozinha, em
                        tempo real.
                      </OverviewText3>
                    </OverviewCard3>
                  </Col>
                </Row>
              </OverviewCardContainer>
            </Valign>
          </Center>
        </OverviewContainer>

        <GridPanel style={{}}>
          <Center>
            <Valign>
              <div
                style={{
                  marginTop: "26px",
                  color: "white",
                  fontFamily: "IBM Plex Sans",
                  fontWeight: "bold",
                  fontSize: "2rem",
                }}
              >
                NOSSO DNA
              </div>
              <div
                style={{
                  display: "inline-block",
                  width: "216px",
                  height: "8px",
                  backgroundColor: "#3f6aea",
                }}
              ></div>
              <h3
                style={{
                  color: "white",
                  fontWeight: "lighter",
                  marginTop: "26px",
                  marginBottom: "20px",
                }}
              >
                O que torna o nosso sistema para restaurantes especial?
              </h3>
            </Valign>
          </Center>

          <div
            style={{
              maxWidth: "1100px",
              marginRight: "auto",
              marginLeft: "auto",
            }}
          >
            <Masonry
              style={{ margin: "0 auto" }}
              className={"my-gallery-class"} // default ''
              elementType={"ul"} // default 'div'
              options={masonryOptions} // default {}
              disableImagesLoaded={false} // default false
            >
              {childElements}
            </Masonry>
          </div>
          <div style={{ backgroundColor: "#222222", height: "128px" }}></div>
        </GridPanel>

        <BrowserAppContainer>
          <Center>
            <Valign>
              <Row
                type="flex"
                gutter={40}
                style={{ maxWidth: "90%", display: "inline-block" }}
              >
                <Col
                  xs={24}
                  sm={24}
                  md={24}
                  lg={24}
                  xl={12}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: " center",
                  }}
                >
                  <div
                    style={{
                      backgroundColor: "white",
                      width: "100px",
                      height: " 100px",
                      marginLeft: "auto",
                      marginRight: "auto",
                    }}
                  ></div>
                  <Laptop
                    src={BrowserApp}
                    alt="Aplicativo para restaurantes"
                  ></Laptop>
                </Col>
                <Col xs={24} sm={24} md={24} lg={24} xl={12}>
                  <BrowserAppTitle>OPERE COMO QUISER</BrowserAppTitle>

                  <div
                    style={{
                      textAlign: "left",
                      width: "216px",
                      height: "8px",
                      backgroundColor: "#3f6aea",
                    }}
                  ></div>
                  <p
                    style={{
                      maxWidth: "600px",
                      color: "white",
                      letterSpacing: "-.5px",
                      fontFamily: "IBM Plex Sans",
                      fontSize: "30px",
                      lineHeight: "1.125!important",
                      opacity: "0.9",
                      textAlign: "left",
                    }}
                  >
                    Se desejar, use a comanda eletrônica com um computador ou
                    laptop.
                  </p>
                  <Row gutter={40} maxWidth={90} type="flex">
                    <Col
                      xs={24}
                      sm={24}
                      md={24}
                      lg={24}
                      xl={12}
                      style={{ display: "flex", flexDirection: "column" }}
                    >
                      <Counter
                        width={40}
                        height={40}
                        fill={"white"}
                        style={{ marginBottom: "12px" }}
                      />
                      <h3
                        style={{
                          fontFamily: "IBM Plex Sans",
                          color: "white",
                          textAlign: "left",
                        }}
                      >
                        Ideal Para o Balcão
                      </h3>
                      <p
                        style={{
                          textAlign: "left",
                          fontFamily: "IBM Plex Sans",
                          color: "white",
                        }}
                      >
                        O aplicativo web foi projetado para casas que operam com
                        balcões de atendimento. Esta ferramenta é usada
                        principalmente para fazer o checkout. Porém, o sistema
                        possui todas as funcionalidades do aplicativo móvel.
                      </p>
                    </Col>
                    <Col
                      xs={24}
                      sm={24}
                      md={24}
                      lg={24}
                      xl={12}
                      style={{ display: "flex", flexDirection: "column" }}
                    >
                      <Money
                        width={40}
                        height={40}
                        fill={"white"}
                        style={{ marginBottom: "12px" }}
                      />
                      <h3
                        style={{
                          fontFamily: "IBM Plex Sans",
                          color: "white",
                          textAlign: "left",
                        }}
                      >
                        Opção Econômica
                      </h3>
                      <p
                        style={{
                          textAlign: "left",
                          fontFamily: "IBM Plex Sans",
                          color: "white",
                        }}
                      >
                        Sabemos que Tablets e Smartphones são aparelhos caros.
                        Por isso, lutamos para atender quem está começando e
                        quem precisa poupar. Com o app web, basta um computador
                        para acessar todos os benefícios da plataforma deli.
                      </p>
                    </Col>
                  </Row>
                  <Row gutter={40}>
                    <Col
                      xs={24}
                      sm={24}
                      md={24}
                      lg={24}
                      xl={12}
                      style={{ display: "flex", flexDirection: "column" }}
                    >
                      <Sync
                        width={40}
                        height={40}
                        fill={"white"}
                        style={{ marginBottom: "12px" }}
                      />
                      <h3
                        style={{
                          fontFamily: "IBM Plex Sans",
                          color: "white",
                          textAlign: "left",
                        }}
                      >
                        Sincronia Absoluta
                      </h3>
                      <p
                        style={{
                          textAlign: "left",
                          fontFamily: "IBM Plex Sans",
                          color: "white",
                        }}
                      >
                        Tanto faz utilizar tablet, celular, computador ou
                        qualquer combinação! As comandas estão sempre
                        atualizadas, independentemente do dispositivo.
                      </p>
                    </Col>
                    <Col
                      xs={24}
                      sm={24}
                      md={24}
                      lg={24}
                      xl={12}
                      style={{ display: "flex", flexDirection: "column" }}
                    >
                      <Web
                        width={40}
                        height={40}
                        fill={"white"}
                        style={{ marginBottom: "12px" }}
                      />
                      <h3
                        style={{
                          fontFamily: "IBM Plex Sans",
                          color: "white",
                          textAlign: "left",
                        }}
                      >
                        Escolha o Navegador
                      </h3>
                      <p
                        style={{
                          textAlign: "left",
                          fontFamily: "IBM Plex Sans",
                          color: "white",
                        }}
                      >
                        Nosso aplicativo web funciona em todos os browsers
                        atuais: Chrome, Firefox, Edge, Safari, Opera entre
                        outros.
                      </p>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Valign>
          </Center>
        </BrowserAppContainer>
        <Footer />
      </OuterContainer>
    )
  }
}

const Center = styled.div`
  display: table;
  width: 100%;
  height: auto;
`
const Valign = styled.div`
  display: table-cell;
  text-align: center;
  vertical-align: middle;
`

const OuterContainer = styled.div``

const Header = styled.div`
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 10;
  color: #3f6aea;
  height: 48px;
  width: 100%;
  margin-bottom: 24px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
`
const BannerContainer = styled.div`
  width: 100%;
  background-color: white;
  height: auto;
`

const OverviewContainer = styled.div`
  width: 100%;
  min-height: 600px;
  height: auto;
  background-color: #dddddd;
`

const OverviewCardContainer = styled.div`
  margin-top: 24px;
  display: inline-block;
  width: 90%;
  @media (min-width: 992px) {
    width: 80%;
  }
`

const OverviewCard1 = styled.div`
  height: auto;

  min-width: 250px;
  min-height: 500px;
  padding: 20px;

  @media (max-width: 992px) {
    min-height: 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: white;
    box-shadow: 0 0 54px rgba(0, 0, 0, 0.2), 0 5px 20px rgba(0, 0, 0, 0.07),
      0 0 1px rgba(0, 0, 0, 0.12);
  }
  @media (min-width: 992px) {
    margin-left: auto;
    width: 60%;
  }
`

const OverviewCard2 = styled.div`
  border-radius: 5px;
  height: auto;

  min-height: 500px;
  min-width: 250px;
  padding: 20px;
  @media (min-width: 992px) {
    background-color: white;
    box-shadow: 0 0 54px rgba(0, 0, 0, 0.2), 0 5px 20px rgba(0, 0, 0, 0.07),
      0 0 1px rgba(0, 0, 0, 0.12);
    width: 60%;
    margin-left: auto;
    margin-right: auto;
  }

  @media (max-width: 992px) {
    min-height: 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`

const OverviewCard3 = styled.div`
  height: auto;

  min-height: 500px;
  min-width: 250px;
  padding: 20px;

  @media (max-width: 992px) {
    min-height: 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  @media (min-width: 992px) {
    width: 60%;
    margin-right: auto;
  }
`

const OverviewTitle1 = styled.h3`
  @media (min-width: 992px) {
    text-align: right;
  }
`

const OverviewTitle2 = styled.h3``

const OverviewTitle3 = styled.h3`
  @media (min-width: 992px) {
    text-align: left;
  }
`

const OverviewDivisor1 = styled.div`
  width: 150px;
  height: 6px;
  background-color: #87a8c5;
  margin-bottom: 12px;
  @media (min-width: 992px) {
    margin-left: auto;
  }
`

const OverviewDivisor2 = styled.div`
  width: 150px;
  height: 6px;
  background-color: #87a8c5;
  margin-bottom: 12px;
  margin-left: auto;
  margin-right: auto;
`

const OverviewDivisor3 = styled.div`
  width: 150px;
  height: 6px;
  background-color: #f8b37b;
  margin-bottom: 12px;
  @media (min-width: 992px) {
    margin-right: auto;
  }
`

const OverviewText1 = styled.p`
  max-width: 600px;
  letter-spacing: -0.5px;
  font-family: "IBM Plex Sans";
  font-size: 17px;
  line-height: 1.125 !important;
  @media (min-width: 992px) {
    text-align: right;
  }
`

const OverviewText2 = styled.p`
  max-width: 600px;
  letter-spacing: -0.5px;
  font-family: "IBM Plex Sans";
  font-size: 17px;
  line-height: 1.125 !important;
`

const OverviewText3 = styled.p`
  max-width: 600px;
  letter-spacing: -0.5px;
  font-family: "IBM Plex Sans";
  font-size: 17px;
  line-height: 1.125 !important;
  @media (min-width: 992px) {
    text-align: left;
  }
`

const BrowserAppContainer = styled.div`
  width: 100%;
  min-height: 600px;
  height: auto;
  padding-bottom: 36px;

  background: rgb(135, 168, 197);
  background: linear-gradient(
    135deg,
    rgba(135, 168, 197, 1) 22%,
    rgba(114, 63, 234, 1) 100%
  );
  @media (min-width: 1200px) {
    padding-top: 76px;
    padding-bottom: 152px;
    background: rgb(135, 168, 197);
    background: linear-gradient(
      45deg,
      rgba(135, 168, 197, 1) 22%,
      rgba(114, 63, 234, 1) 100%
    );
  }
`

const BrowserAppTitle = styled.h1`
  text-align: left;
  font-family: "IBM Plex Sans";
  font-size: 2rem;
  color: white;
  font-weight: bold;
  margin-bottom: 10px;
  margin-top: 72px;

  @media (max-width: 1200px) {
    margin-top: 24px;
  }
`

const Laptop = styled.img`
  @media (min-width: 1200px) {
    margin-left: -500px;
    display: inline-block;
    max-width: none;
    margin-bottom: 0px;
  }

  margin-top: 68px;
`

const DNACard = styled.div`
  cursor: pointer;
  &:hover {
    background: #111111;
  }
  border-radius: 5px;
  vertical-align: baseline;
  margin-bottom: 10px;
  background-color: #292929;
  width: 260px;
  height: 80px;
  padding: 20px;
  box-shadow: "0 8px 12px 0 rgba(0, 0, 0, 0.1), 0 2px 20px 0 rgba(0, 0, 0, 0.1), 0 4px 8px -2px rgba(0, 0, 0, 0.1)";
`

const DNATitleContainer = styled.h3`
  float: left;
  font-weight: 500;
  line-height: 1.1;
  width: 160px;
  margin-top: 10px;
  text-align: left;
  font-family: "IBM Plex Sans";
  font-size: 1em;
  color: ${props => props.color};
`

const DNACardExpanded = styled.div`
  cursor: pointer;
  &:hover {
    background: #111111;
  }
  border-radius: 5px;
  vertical-align: baseline;
  margin-bottom: 10px;
  background-color: #292929;
  width: 260px;
  height: 260px;
  padding: 20px;
  box-shadow: "0 8px 12px 0 rgba(0, 0, 0, 0.1), 0 2px 20px 0 rgba(0, 0, 0, 0.1), 0 4px 8px -2px rgba(0, 0, 0, 0.1)";
`

const GridPanel = styled.div`
  width: 100%;
  height: auto;
  background-color: #222222;
`

const Summary = styled.h1`
  margin-top: 32px;
  font-size: 24px;
  font-weight: 400;
  font-family: "IBM Plex Sans";
  padding-right: 12px;
  padding-left: 12px;
  width: 100%;
`

const Summary2 = styled.h2`
  padding-right: 12px;
  padding-left: 12px;
  font-size: 20px;
  font-weight: 300;
  font-family: "IBM Plex Sans";
  width: 100%;
`

const Logo = styled.div`
  margin-top: 10%;
  margin-bottom: 5%;
  color: #3f6aea;
  font-family: "Righteous";
  font-size: 128px;
`

export default IndexDesktop
