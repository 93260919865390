import React from "react"

function SvgSync(props) {
  return (
    <svg height={512} viewBox="0 0 60 61" width={512} fill={"white"} {...props}>
      <g fillRule="nonzero">
        <path d="M50.205 12.511C48.865 5.331 42.843 0 35.93 0a14.211 14.211 0 00-11.844 6.518c-.803-.18-1.623-.27-2.446-.268-5.565 0-10.418 4.23-11.6 9.971C4.445 16.578 0 21.545 0 27.6 0 33.886 4.791 39 10.68 39h10.591a17.995 17.995 0 1034.519-3.436A13.7 13.7 0 0060 25.59a13.193 13.193 0 00-9.795-13.079zM39 58c-8.837 0-16-7.163-16-16s7.163-16 16-16 16 7.163 16 16c-.01 8.832-7.168 15.99-16 16zm15.92-24.374A17.981 17.981 0 0021.721 37H10.68C5.894 37 2 32.783 2 27.6s3.894-9.4 8.68-9.4h.2a1 1 0 00.99-.861c.73-5.181 4.93-9.089 9.77-9.089a9.07 9.07 0 012.612.377 1 1 0 001.148-.443A12.3 12.3 0 0135.93 2c6.145 0 11.481 4.94 12.41 11.491a1 1 0 00.816.844C54.28 15.242 58 19.975 58 25.59a11.785 11.785 0 01-3.08 8.036z" />
        <path d="M31.293 39.293l-1.149 1.148A9.01 9.01 0 0139 33a1 1 0 000-2 11.011 11.011 0 00-10.912 9.674l-1.381-1.381a1 1 0 00-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414zM48.293 39.293l-3 3a1 1 0 001.414 1.414l1.271-1.271A9 9 0 0139 51a1 1 0 000 2 11.005 11.005 0 0010.98-10.606l1.313 1.313a1 1 0 001.414-1.414l-3-3a1 1 0 00-1.414 0z" />
      </g>
    </svg>
  )
}

export default SvgSync
